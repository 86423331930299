import React from "react";
import {
  SEO,
  PageLayout,
  VerticalCarousel,
  componentIterator,
  Image,
  Content,
  CallToActionBanner,
  OutboundLink,
  InternalLink,
  Call,
  Background,
  ContinuousScroll,
  PatternedImageRow,
  BasicCarousel,
} from "@bluefin/components";
import { Grid, Header, Button } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      externalOrderOnlinePage,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout className={"home-page"}>
          <div className={"hero-images-container"}>
            <VerticalCarousel
              size={1}
              pageIndicatorIcon={"square outline"}
              orientation={"right"}
              items={componentIterator({
                iterator: getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                }),
                component: <Image className={"image"} />,
                propMap: { src: "__all__" },
              })}
            />
          </div>
          <Grid
            columns={2}
            stackable={true}
            doubling={true}
            className={"welcome-to-grid"}
          >
            <Grid.Column className={"welcome-copy-column"}>
              <Content stackable={true} textAlign={"left"}>
                <Header as={"h1"} className={"welcome-to-header"}>
                  WELCOME TO {fishermanBusiness.name}
                </Header>
                <Header as={"h2"} className={"welcome-to-subheader"}>
                  DINE WITH US
                </Header>
                <Content.Markup
                  width={16}
                  isMarkdown={true}
                  content={fishermanBusiness.aboutMarkdown}
                />
              </Content>
              <Grid.Column width={16}>
                <CallToActionBanner stackable={true}>
                  <CallToActionBanner.CTA>
                    <Button
                      as={OutboundLink}
                      to={externalOrderOnlinePage.url}
                      primary={true}
                    >
                      Order Online
                    </Button>
                  </CallToActionBanner.CTA>
                  <CallToActionBanner.CTA>
                    <Button to={"/menu/"} as={InternalLink}>
                      VIEW MENU
                    </Button>
                  </CallToActionBanner.CTA>
                  <CallToActionBanner.CTA>
                    <Call
                      phoneNumber={
                        fishermanBusiness.primaryLocation.phoneNumber
                      }
                      as={"button"}
                    />
                  </CallToActionBanner.CTA>
                </CallToActionBanner>
              </Grid.Column>
            </Grid.Column>
            <Grid.Column className={"welcome-image-column"}>
              <Image
                className={"image"}
                src={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "Content.Image",
                  componentIdentifier: "welcome",
                  numToSelect: 1,
                })}
              />
            </Grid.Column>
          </Grid>
          <div className={"image-gallery-container"}>
            <Background
              columns={1}
              stackable={true}
              className={"images-background"}
            >
              <Background.Pattern type={"CIRCLE"}>
                <ContinuousScroll
                  className={"patterned-images-container-scroll"}
                >
                  <PatternedImageRow
                    containerAs={ContinuousScroll.Item}
                    images={getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "ImageCollection",
                    })}
                  />
                </ContinuousScroll>
              </Background.Pattern>
            </Background>
          </div>
          <div className={"reviews-container"}>
            <BasicCarousel
              size={3}
              pageIndicatorIcon={null}
              mobile={{ arrowsPosition: "top" }}
            />
          </div>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      aboutMarkdown
      primaryLocation {
        phoneNumber
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          _id
        }
      }
    }
    externalOrderOnlinePage: fishermanBusinessWebsitePage(
      title: { eq: "Order Online" }
      pageType: { eq: "External" }
    ) {
      url
    }
  }
`;
